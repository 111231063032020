<template>
    <div id="work" class="d-blck" v-if="ready">
        <div class="bg-hr text-white p-2 h5 opacity-75 text-center">
            {{ data.company.name }}
        </div>
        <!-- 入职信息 -->
        <div class="row px-1 py-2 mx-2 my-3 bg-white rounded">
            <div class="text-center col fs-8" v-if="data.Induction_time">
                <p class="mb-1">入职日期</p>
                <p class="mb-1 fw-bold text-danger">{{ data.Induction_time }}</p>
                
            </div>
            <div class="text-center col fs-8" v-if="data.pay_time">
                <p class="mb-1">发新日期</p>
                <p class="mb-1 fw-bold text-danger">{{ data.pay_time }} 日</p>
                
            </div>
            <div class="text-center col fs-8" v-if="data.pay_cycle">
                <p class="mb-1">周 期</p>
                <p class="mb-1 fw-bold text-danger">{{ data.pay_cycle }}</p>
                
            </div>
            <div class="text-center col fs-8" v-if="data.current_price">
                <p class="mb-1">当前工价</p>
                <p class="mb-1 fw-bold text-danger">{{ data.current_price }} 元</p>
                
            </div>
        </div>
        <!-- 打卡考勤 -->
        <Working></Working>
        <!-- 详细信息 -->
        <List></List>
    </div>
    <div v-if="!ready" class="text-danger fs-1 py-3 text-center" @click="router.go(-1)">
        您还未入职，不能打卡
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import Axios from 'axios'
    import { useRouter } from 'vue-router'
    import List from '@/components/worklist/list'
    import Working from './working.vue';
    const ready = ref(true)
    const router = useRouter()
    const data = ref({
        company: {id: 100, name: '一二一通信（苏州）有限公司'},
        signup_time: '20220222',  //投递日期
        Induction_time: '20220223', //入职日期
        pay_time: 15, //发薪日
        pay_cycle: '1-31', //发薪周期
        current_price: 18.5 //当前单价
    })

    onMounted(() => {

    })


</script>

<style>
#work .bg-tag {
    background-color: rgb(243, 243, 243);
}
</style>