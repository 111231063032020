<template>
    <div class="tab row text-center bg-white p-2 m-0 mx-2 rounded">
        <div class="col btn-hr-tab" @click="show(1,'worklist')">考勤明细</div>
        <div class="col btn-hr-tab" @click="show(3)">打卡规范</div>
        <div class="col btn-hr-tab" @click="show(4)">协议须知</div>
    </div>
    <div class="p-2 border-top" v-if="showNum == 1">
        <table class="worklist w-100">
            <tr class="title w-100 border-bottom">
                <th class="text-center">打卡日期</th>
                <th class="text-center">工时</th>
                <th class="text-center">登记工时</th>
                <th class="text-center">单价</th>
                <th class="text-center">金额</th>
                <th class="text-center">钱包</th>
            </tr>
            <tbody v-for="(items,index) in worklistData" :key="index">
                <tr class="list border-bottom">
                    <td class="text-center text-muted">{{ items.time }}</td>
                    <td class="text-center text-muted">{{ items.task_time }}</td>
                    <td class="text-center text-muted">{{ items.register_time }}</td>
                    <td class="text-center text-muted">{{ items.price }}</td>
                    <td class="text-center text-muted">{{ items.money }}</td>
                    <td class="text-center text-muted">{{ items.wallet > 0 ? items.wallet : '未入账' }}</td>
                </tr>
                <tr class="border-bottom px-2" v-if="items.des">
                    <td colspan="6" class="text-danger">{{ items.des }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <div class="p-2 border-top" v-if="showNum == 2">
        settlement list
    </div> -->
    <div class="p-3 border-top" v-if="showNum == 3" v-html="Working">
    </div>
    <div class="p-3 border-top" v-if="showNum == 4" v-html="Treaty">
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    // import Axios from 'vue'
    const showNum = ref(0)
    function show(ids) {
        showNum.value = ids
        // Axios.get('')
    }
    const Working = ref(
        "<p>1. 上下班卡：在打完企业里的考勤时拍下考勤结果(要求-姓名和日期清晰)</p><p>2. 在食堂内就餐的时候自拍打卡（要有食堂内景）</p><p>3. 如车间内不可带手机，可以车间门口自拍（要有车间大门）</p>"
    )
    const Treaty = ref(
        "<p>1. </p><p>2. </p><p>3. </p><p>4. </p>"
    )
    const worklistData = ref([])
    const a = 5
    for(var i=0; i<a; i++) {
        worklistData.value.push(
            {
                id: 100 +i,
                time: '2022031' + i, //打卡日期
                task_time: 10.5, //上班工时
                register_time: 11, //员工登记工时
                price: 18.5, //当前单价
                money: 200, //审核状态  0待审， 1不通过， 2通过
                wallet: 0, //空为未入账， 入账到钱包金额
                des: ''
            }
        )
    }

    const settlementData = ref([])
</script>

<style>

</style>