<template>
    <div id="show_time" class="text-center fs-8 px-1 py-3 mx-2 fs-3 text-wrap border-top pt-2 rounded bg-white text-shadow"></div>
     <div class="row p-2 border-top border-bottom mx-2 my-3 rounded bg-white" v-if="clockType == 'photograph'">
        <div class="d-flex justify-content-center col fs-8 px-1 align-items-center" style="height:100px">
            <img :src="data.up_image" v-if="data.up_image">
            <div class="file img opacity-75 rounded-circle w-shadow" :class="{'bg-hr': !data.up_image}">
                <span class="fs-12 fw-bold text-white lh-sm mt-3 d-flex" v-if="!data.up_image">上 班打 卡</span>
                <input ref="up_image" type="file" name="resumeavatar" id='upimg' accept="image/*" capture="camera" @change="saveImage($event, 'up_image')"/>  
            </div>
        </div>
        <div class="d-flex justify-content-center col fs-8 px-1 align-items-center">
            <img :src="data.eat_image" v-if="data.eat_image">
            <div class="file img opacity-75 rounded-circle w-shadow" :class="{'bg-hr': !data.eat_image}">
                <span class="fs-12 fw-bold text-white mt-3 lh-sm d-flex" v-if="!data.eat_image">就 餐打 卡</span>
                <input ref="eat_image" type="file" name="resumeavatar" id='upimg' accept="image/*" capture="camera" @change="saveImage($event, 'eat_image')"/>  
            </div>
        </div>
        <div class="d-flex justify-content-center col fs-8 px-1 align-items-center">
            <img :src="data.dw_image" v-if="data.dw_image">
            <div class="file img opacity-75 rounded-circle w-shadow" :class="{'bg-hr': !data.dw_image}">
                <span class="fs-12 fw-bold text-white mt-3 lh-sm d-flex" v-if="!data.dw_image">下 班打 卡</span>
                <input ref="dw_image" type="file" name="resumeavatar" id='upimg' accept="image/*" capture="camera" @change="saveImage($event, 'dw_image')"/>  
            </div>
        </div>
    </div>

    <div class="row p-2 border-top border-bottom mx-2 my-3 rounded bg-white" v-if="clockType == 'machine'">
        <div class="col">
            下班时间: 8:30  
        </div>
        <div class="col">
            下班时间: 20:30
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, onUnmounted } from 'vue'
    import Axios from 'axios'
    import wx from 'weixin-js-sdk'
    import { fun } from '@/lib/unitls'

    // 考勤方式 photograph：拍照  machine：考勤机
    const clockType = ref('photograph')

    const up_image = ref()
    const eat_image = ref()
    const dw_image = ref()

    const data = ref({
        up_image: '', //上班照片
        up_gps: '', //上班定位
        eat_image: '', //中餐照片
        eat_gps: '', //中餐定位
        dw_image: '', //下班照片
        dw_gps: '' //下班定位
    })
    
    //上传图片
    

    function saveImage(event, key) {
        if(key == 'up_image' && up_image.value.files[0]) {
            //声名form 对像 
            const form = new FormData()
            //把文件格式转成form
            form.append('file', up_image.value.files[0])
            //图片上传
            Axios.post('/api/v1/upload/image', form).then((response) => {
                if(response.data.code == 0) {
                    data.value.up_image = response.data.data.path
                    data.value.up_gps = '30.22233,120.343444'
                    alert('打卡成功')
                }
            })
        } else if(key == 'eat_image' && eat_image.value.files[0]) {
            // Axios.get('') 检测是否有上班卡， 如无则不打卡
            const form = new FormData()
            form.append('file', eat_image.value.files[0])
            Axios.post('/api/v1/upload/image', form).then((response) => {
                if(response.data.code == 0) {
                    data.value.eat_image = response.data.data.path
                    data.value.eat_gps = '30.22233,120.343444'
                    alert('打卡成功')
                }
            })
        } else if(key == 'dw_image' && dw_image.value.files[0]) {
            // Axios.get('') 检测是否有上班卡， 如无则不打卡
            const form = new FormData()
            form.append('file', dw_image.value.files[0])
            Axios.post('/api/v1/upload/image', form).then((response) => {
                if(response.data.code == 0) {
                    data.value.dw_image = response.data.data.path
                    data.value.dw_gps = '30.22233,120.343444'
                    const register_time = prompt('请输入今天的上班工时')
                    console.log(register_time)
                    alert('打卡成功')
                }
            })
        }
    }
    
    // Axios({
    //     method: 'post',
    //     url: 'http://my.service.com/index.php/opcode/6002',
    //     data:{ url:location.href.split('#')[0] } //向服务端提供授权url参数，并且不需要#后面的部分
    // }).then((res)=>{
    //     wx.config({
    //     debug: true, // 开启调试模式,
    //     appId: res.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
    //     timestamp: res.timestamp, // 必填，生成签名的时间戳
    //     nonceStr: res.nonceStr, // 必填，生成签名的随机串
    //     signature: res.signature,// 必填，签名，见附录1
    //     jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    //     });
    // })

    const settime = ref()
    onMounted(()=>{
        //时间
        settime.value = setInterval(()=>fun("show_time"),1000)
        //微信位置
        // wx.ready(function () {
        //     wx.checkJsApi({
        //         jsApiList: [
        //             'getLocation'
        //         ],
        //         success: function (res) {
        //             // alert(JSON.stringify(res));
        //             // alert(JSON.stringify(res.checkResult.getLocation));
        //             if (res.checkResult.getLocation == false) {
        //                 alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
        //                 return;
        //             }
        //         }
        //     });
        //     //微信获取用户位置
        //     wx.getLocation({
        //     type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        //     success: function (res) {
        //         var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
        //         //var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
        //         //var speed = res.speed; // 速度，以米/每秒计
        //         //var accuracy = res.accuracy; // 位置精度
        //         //alert(latitude);
        //         if(latitude){
        //             // document.getElementById("gpsla").value=latitude
        //             // document.getElementById("gpsln").value=longitude
        //             // document.getElementById("dwgpsla").value=latitude
        //             // document.getElementById("dwgpsln").value=longitude
        //             // return {code: 0, name: latitude + ',' + longitude}
        //             alert(latitude)
        //         }else{
        //             //alert(latitude);
        //             // document.getElementById('gps1').innerText="请点击此处刷新页面后再打卡上传照片"
        //             return {code: 1, gps: "请点击此处刷新页面后再打卡上传照片"}
        //         }
        //     }
            
        //     });
        // });
    })

    onUnmounted(() => {
        //销毁时间
        clearInterval(settime.value)
    })
</script>

<style scoped>
.text-shadow {
    text-shadow: 1px 1px 3px #000000;
}
.w-shadow {
    box-shadow: inset 0 0 10px 10px rgba(255, 254, 254, 0.3);
}
.img {
    text-align: center;
    margin-bottom:5px;
}

img {
    width: 80px;
    height: 90px;
}
.file {
    position: absolute;
    width: 80px;
    height: 90px;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
}
.file input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

</style>